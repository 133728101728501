<template>
    <default-layout>
        <!-- <header-image/> -->
        <SpainhnNavigationBreadcrumb :navigation-links="links" />
        <div class="container-fluid px-md-5 px-1">
            <div class="d-flex justify-content-around pt-4 mb-3" style="">
                <div class="d-flex" style="width:100%">
                    <icon-base :width="55" :height="55" 
                        :iconColor="'rgb(0,0,0)'" >
                        <icon-casttle></icon-casttle>
                    </icon-base>
                    <div class="ms-3 h-100 d-flex flex-column text-start">
                        <p class="mb-0 mt-auto spainhn-subtitle-2-black">{{nombreDeMonumentoPorIdioma}}</p>
                        <small class="mb-0 mt-auto spainhn-subtitle-4-black">{{address}}</small>
                    </div>
                </div>
            </div>

            <div class="container-fluid mb-5">
                <div class="row">
                    <div class="d-flex flex-column pt-3 ps-3 pe-3 col-12">     
                        <h5 class="mb-0"><strong><small>{{ $t('default.MENSAJE_SERVICIOS_ALOJAMIENTO_HEADER', {msg: nombreDeMonumentoPorIdioma}) }}</small></strong></h5>
                        <p class="mb-0"><strong><small>{{ $t('default.MENSAJE_SERVICIOS_ALOJAMIENTO_SUB', {msg: nombreDeMonumentoPorIdioma}) }}</small></strong></p>

                        <div class="h-100 pt-5 pb-5 spainhn-bg-gris-cajas ps-3 pe-0 spainhn-text d-flex flex-column" style="border-radius:10px;">
                            <span class="text-start px-3 text-wrap mx-auto text-columns" v-html="descripcion"></span>                                      
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <p class="spainhn-small-text">{{ $t('default.SERVICES_CONTACT_DETAILS') }}.</p>
                <mdb-btn color="indigo" class="spainhn-button mb-3">{{ $t('default.SPAINHN_BUTTON_CONTACT') }}</mdb-btn>
            </div>
        </div>
        <mdb-container>
            <components-cloud />
        </mdb-container>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'
// import HeaderImage from '../components/HeaderImage.vue'
import IconBase from '../components/IconBase.vue'
import IconCasttle from '../components/icons/IconCasttle.vue';
import SpainhnNavigationBreadcrumb from "../components/SpainhnNavigationBreadcrumb";
import ComponentsCloud from "../components/ComponentsCloud";
import ApiService from '../services/ApiService';

export default {
    components:{
        DefaultLayout, 
        IconBase,
        IconCasttle,
        SpainhnNavigationBreadcrumb,
        ComponentsCloud
    },
    data(){
        return{
            // monumentoNombre:"",
            address:null,
            descripcion: null,
            videoId: 'YU5hCOSFki8',
            edificios:[],
            imagenes:[],
            slide:0,
            indicaciones:null,
            horarios:null,
            castilloId: null,
            activeImage: 0,
            nombreUrl: null,
            restaurantes:[],
            actividades:[],
            id: null,
            info: null,
            tipo: "Alojamientos",
            tipoHeader: null,
            folderOldId: null,
            tipoSubheader: null,
            monumentoUrl:"",
            monumentoNombres: [],
            links: [],
            provincia: "",
            municipio: "",
            comunidad: ""
            // Con esta variable determinamos el status del icono        
        }
    },
    methods:{
        showContent(){
            console.log('click en castillo');
        },
        prevImage(){
            let $vm = this;
            if($vm.activeImage == 0){
                $vm.activeImage = $vm.imagenes.length;
            }
        },
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos(){
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.$route.params.monumentoNombre)
        },
        goToEntradas(){
            let $vm = this;
            $vm.$router.push("/castillos/tarifas/" + $vm.$route.params.monumentoNombre )
        },
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        }
    },
    setup() {
        
    },
    created(){
        console.log()
        let $vm = this;
        $vm.monumentoUrl = $vm.$route.params.monumentoNombre;

        $vm.tipoHeader = "Habitaciones de "
        $vm.tipoSubheader = "Alojate en "  
        
        $vm.$i18n.locale = $vm.$store.getters.getIdioma;

        if($vm.$i18n.locale == null){
            $vm.$i18n.locale = 'es';
        }
        
        ApiService.getServicioByMonumento($vm.$i18n.locale, $vm.monumentoUrl, "alojamiento")
            .then( result => {
                
                // console.log(result);
                $vm.info = result.data;
                $vm.address = result.data.direccion;
                $vm.descripcion = result.data.descripcion;
                $vm.monumentoNombres = result.data.nombres;

                this.provincia  = result.data.provincia;
                this.comunidad = result.data.comunidad;
                this.municipio = result.data.municipio
                this.links = [
                    { text: this.monumentoNombres[0].texto, navigation: true, navigationLink: `/castillos/${this.monumentoUrl}` },
                    { text: this.provincia },
                    { text: this.comunidad }
                ];
            })
            .catch( err =>{
                console.log(err);
            })
    },
    computed: {
        selectedIdiomaIndex(){
            let $vm=this;
            let idiomaIndex = 0;
            if($vm.$i18n.locale == 'es'){
                idiomaIndex = 0;
            }
            else if($vm.$i18n.locale == 'en'){
                idiomaIndex = 1;
            }
            else if($vm.$i18n.locale == 'fr'){
                idiomaIndex = 2;
            }
            else if($vm.$i18n.locale == 'de'){
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma(){
            let $vm=this;
            
            return $vm.$i18n.locale;
        },
        nombreDeMonumentoPorIdioma(){
            let $vm = this;

            if( $vm.monumentoNombres[$vm.selectedIdiomaIndex] ){
                return $vm.monumentoNombres[$vm.selectedIdiomaIndex].texto;
            }
            else{
                return $vm.monumentoNombre;
            }
        }

    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

        @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}
</style>